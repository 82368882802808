// import SplitText from '../libs/gsap/SplitText';
import anime from 'animejs';
// import gsap from 'gsap';

// export const textEffects = () => {
// 	let target = document.querySelectorAll('.splitting');

// 	if (!target.length) return;

// 	target.forEach((el) => {
// 		let content;
// 		let test = el.querySelectorAll('* > *:not(br):not(span)');

// 		if (test.length > 0) {
// 			content = el.querySelectorAll('* > *:not(br):not(span)');
// 		}

// 		new SplitText(content, {
// 			type: 'lines',
// 			linesClass: 'overflow-hidden',
// 		});

// 		content.forEach((el) => {
// 			const lines = el.querySelectorAll('.overflow-hidden');

// 			new SplitText(lines, {
// 				type: 'lines',
// 				linesClass: 'split__line',
// 			});
// 		});
// 	});
// };

const heroAnimation = () => {
	const hero = document.querySelector('.hero');
	const heroTitle = hero.querySelector('.hero__title');
	const heroSubtitle = hero.querySelector('.hero__subtitle');
	const heroCta = hero.querySelector('.hero__cta');

	const tl = anime.timeline({
		easing: 'easeOutExpo',
		duration: 1000,
		delay: 500,
	});
};

const scrollAnimations = () => {
	let elements = document.querySelectorAll('[data-enter]');
	const scrollObserver = new IntersectionObserver(
		(entries, observer) => {
			entries.forEach((entry) => {
				if (!entry.isIntersecting) return;

				const element = entry.target;

				console.log('Yooooo');

				switch (element.dataset.enter) {
					case 'hero':
						console.log('Hero');

						const heroTitle = element.querySelector('.c-hero__title'),
							heroSubtitle = element.querySelector('.c-hero__subtitle'),
							heroInner = element.querySelector('.c-hero__inner'),
							heroNav = document.querySelector('.js-main-nav-home'),
							heroButtons = element.querySelector('.c-hero__buttons'),
							heroUsps = element.querySelector('.c-hero__usps'),
							heroLogo = heroNav.querySelectorAll('.logo'),
							heroMedia = element.querySelector('.c-hero__media'),
							solutionsContainer =
								document.querySelector('.c-solutions'),
							solutions =
								solutionsContainer.querySelectorAll(
									'.c-solutions__item'
								),
							solutionsTitle = solutionsContainer.querySelector(
								'.c-solutions__title-noncta'
							);

						const tl = anime.timeline({
							easing: 'easeOutExpo',
							duration: 1000,
						});

						// tl.add({
						// 	targets: heroMedia,
						// 	opacity: [0, 1],
						// 	duration: 1500,
						// });
						tl.add({
							targets: heroInner,
							opacity: [0, 1],
							duration: 0,
						})
							// .add(
							// 	{
							// 		targets: heroMedia,
							// 		opacity: [0, 1],
							// 		duration: 1500,
							// 	},
							// 	'0'
							// )
							.add(
								{
									targets: heroLogo,
									opacity: [0, 1],
								},
								'-=800'
							)
							.add(
								{
									targets: heroNav,
									opacity: [0, 1],
									duration: 500,
									complete: () => {
										document.body.classList.add('nav-entered');
									},
								},
								'+=0'
							)
							.add(
								{
									targets: heroTitle,
									opacity: [0, 1],
									translateY: ['4rem', '0'],
									duration: 2000,
								},
								'+=200'
							);

						if (heroSubtitle !== null) {
							tl.add(
								{
									targets: heroSubtitle,
									opacity: [0, 1],
									translateY: ['4rem', '0'],
									duration: 1000,
									easing: 'easeOutCubic',
								},
								'-=1100'
							);
						}

						if (heroButtons !== null) {
							tl.add(
								{
									targets: heroButtons,
									opacity: [0, 1],
									translateY: ['2rem', '0'],
									duration: 1100,
									easing: 'easeOutCubic',
								},
								'-=100'
							);
						}

						if (solutionsTitle !== null) {
							tl.add(
								{
									targets: solutionsTitle,
									opacity: [0, 1],
									translateY: ['4rem', '0'],
									duration: 2000,
								},
								'-=200'
							);
						}

						tl.add({
							targets: heroUsps,
							opacity: [0, 1],
							duration: 1000,
						});

						break;
					case 'slide':
						break;

					default:
						anime({
							targets: element,
							delay: element.dataset.enterDelay || 0,
							opacity: [0, 1],
							translateY: ['2rem', '0'],
							duration: 1e3,
							easing: 'easeOutCubic',
						});

						break;
				}

				// scrollElementEntrance(entry.target);
				entry.target.classList.add('is-inview');
				observer.unobserve(entry.target);
			});
		},
		{
			rootMargin: '0px 0px -15% 0px',
		}
	);

	elements.forEach((el) => {
		scrollObserver.observe(el);
	});
};

const initAnimations = () => {
	// textEffects();
	scrollAnimations();
};

export { initAnimations };
