// CSS
import "@/css/site.css";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';


// JS
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';

// import hyperform from 'hyperform';
import GLightbox from 'glightbox';
import Glide from '@glidejs/glide';
import 'lazysizes';

import { Ticker } from './components/ticker';
import { initAnimations } from './components/contentAnimations';

const INCATRO = window.INCATRO || {};

Alpine.plugin(collapse);
Alpine.start();
window.Alpine = Alpine;

INCATRO.init = function () {
	console.log('# INIT');

	// Run default functions
	// INCATRO.gallery();
	INCATRO.animateInView();
	// INCATRO.formValidation();

	Ticker();
	initAnimations();
	initSliders();

	// sal({
	//     threshold: 0.1,
	//     rootMargin: "40px 0"
	// });

	// elements.autoplayVideos &&
	// 	elements.autoplayVideos.forEach((video) => {
	// 		const videoPlaceholder = video.parentNode.querySelector('.video-autoplay-placeholder');
	// 		const scrollObserver = new IntersectionObserver((entry) => {
	// 			entry.forEach((element) => {
	// 				element.isIntersecting
	// 					? ((video.style.opacity = 1), video.play())
	// 					: (t.pause(), (video.style.opacity = 0));
	// 			});
	// 		});

	// 		// Calling load() aborts all ongoing operations involving this media element,
	// 		// then begins the process of selecting and loading an appropriate media resource
	// 		video.load(),
	// 			video.addEventListener('playing', () => {
	// 				// video is playin so hide the placeholder
	// 				videoPlaceholder.classList.add('hide'),
	// 					video.classList.contains('home-hero-video')
	// 						? scrollObserver.observe(elements.homeHero)
	// 						: scrollObserver.observe(videoPlaceholder);
	// 			});
	// 	});
};

/**
 * Form Validation with Hyperform
 */
INCATRO.formValidation = function () {
	hyperform(window, {
		classes: {
			warning: 'mt-4 text-red-500 text-12',
			invalid: 'border-red-500',
		},
	});

	hyperform.addTranslation('nl', {
		TextTooLong: 'Kort deze tekst in tot %l tekens of minder (u gebruikt nu %l tekens).',
		TextTooShort: 'Gebruik minstens %l tekens (u gebruikt momenteel %l tekens).',
		ValueMissing: 'Vul dit veld in.',
		CheckboxMissing: 'Vink dit vakje aan als u wilt doorgaan.',
		RadioMissing: 'Selecteer een van deze opties.',
		FileMissing: 'Selecteer een bestand.',
		SelectMissing: 'Selecteer een item in de lijst.',
		InvalidEmail: 'Voer een e-mailadres in.',
		InvalidURL: 'Voer een URL in.',
		InvalidDate: 'Voer een geldige datum in.',
		PatternMismatch: 'Gebruik de gevraagde indeling.',
		PatternMismatchWithTitle: 'Gebruik de gevraagde indeling: %l.',
		NumberRangeOverflow: 'Selecteer een waarde die niet hoger is dan %l.',
		DateTimeRangeOverflow: 'Selecteer een waarde die niet later is dan %l.',
		NumberRangeUnderflow: 'Selecteer een waarde die niet lager is dan %l.',
		DateTimeRangeUnderflow: 'Selecteer een waarde die niet vroeger is dan %l.',
		StepMismatch: 'Selecteer een geldige waarde. De twee dichtstbijzijnde geldige waarden zijn %l en %l.',
		StepMismatchOneValue: 'Selecteer een geldige waarde. De dichtstbijzijnde geldige waarde is %l.',
		BadInputNumber: 'Voer een getal in.',
		'Please match the requested type.': 'Pas de invoer aan aan het vereiste type.',
		'Please comply with all requirements.': 'Voldoe aan alle vereisten.',
		'Please lengthen this text to %l characters or more (you are currently using %l characters).':
			'Verleng deze tekst tot ten minste %l tekens (u gebruikt momenteel %l tekens).',
		'Please use the appropriate format.': 'Gebruik de juiste indeling.',
		'Please enter a comma separated list of email addresses.':
			"Voer een door komma's gescheiden lijst van e-mailadressen in.",
		'Please select a file of the correct type.': 'Selecteer een bestand van het juiste type.',
		'Please select one or more files.': 'Selecteer een of meer bestanden.',
		'any value': 'elke waarde',
		'any valid value': 'elke geldige waarde',
	});
	hyperform.addTranslation('de', {
		TextTooLong: 'Bitte kürzen Sie diesen Text auf maximal %l Zeichen (Sie verwenden derzeit %l Zeichen).',
		TextTooShort: 'Bitte verwenden Sie zumindest %l Zeichen (Sie verwenden derzeit %l Zeichen).',
		ValueMissing: 'Bitte füllen Sie dieses Feld aus.',
		CheckboxMissing: 'Bitte klicken Sie dieses Kästchen an, wenn Sie fortsetzen wollen.',
		RadioMissing: 'Bitte wählen Sie eine dieser Optionen.',
		FileMissing: 'Bitte wählen Sie eine Datei.',
		SelectMissing: 'Bitte wählen Sie einen Eintrag in der Liste.',
		InvalidEmail: 'Bitte geben Sie eine E-Mail-Adresse ein.',
		InvalidURL: 'Bitte geben Sie eine Internetadresse ein.',
		//InvalidDate:"",
		PatternMismatch: 'Bitte halten Sie sich an das vorgegebene Format.',
		PatternMismatchWithTitle: 'Bitte halten Sie sich an das vorgegebene Format: %l.',
		NumberRangeOverflow: 'Bitte wählen Sie einen Wert, der nicht größer ist als %l.',
		DateRangeOverflow: 'Bitte wählen Sie einen Wert, der nicht später ist als %l.',
		TimeRangeOverflow: 'Bitte wählen Sie einen Wert, der nicht später ist als %l.',
		NumberRangeUnderflow: 'Bitte wählen Sie einen Wert, der nicht kleiner ist als %l.',
		DateRangeUnderflow: 'Bitte wählen Sie einen Wert, der nicht früher ist als %l.',
		TimeRangeUnderflow: 'Bitte wählen Sie einen Wert, der nicht früher ist als %l.',
		StepMismatch: 'Bitte wählen Sie einen gültigen Wert. Die zwei nähesten gültigen Werte sind %l und %l.',
		StepMismatchOneValue: 'Bitte wählen Sie einen gültigen Wert. Der näheste gültige Wert ist %l.',
		BadInputNumber: 'Bitte geben Sie eine Nummer ein.',
		'Please match the requested type.': 'Bitte passen Sie die Eingabe dem geforderten Typ an.',
		'Please comply with all requirements.': 'Bitte erfüllen Sie alle Anforderungen.',
		'Please lengthen this text to %l characters or more (you are currently using %l characters).':
			'Bitte verlängern Sie diesen Text auf mindestens %l Zeichen (Sie verwenden derzeit %l Zeichen).',
		'Please use the appropriate format.': 'Bitte verwenden Sie das passende Format.',
		'Please enter a comma separated list of email addresses.':
			'Bitte geben Sie eine komma-getrennte Liste von E-Mail-Adressen an.',
		'Please select a file of the correct type.': 'Bitte wählen Sie eine Datei vom korrekten Typ.',
		'Please select one or more files.': 'Bitte wählen Sie eine oder mehrere Dateien.',
		'any value': 'jeder Wert',
		'any valid value': 'jeder gültige Wert',
	});

	var formLanguage = window.formLanguage || 'en';
	hyperform.setLanguage(formLanguage);
};

/**
 * Animate elements into View
 */
INCATRO.animateInView = function () {
	console.log('# ANIMATE IN VIEW');

	const SELECTOR = '[data-animate]';
	const ANIMATE_CLASS_NAME = 'transform-none';

	// Observer options.
	const options = {
		root: null,
		rootMargin: '0px',
		threshold: 0.7,
	};

	const animate = (element) => element.classList.add(ANIMATE_CLASS_NAME);

	const isAnimated = (element) => element.classList.contains(ANIMATE_CLASS_NAME);

	const intersectionObserver = new IntersectionObserver((entries, observer) => {
		entries.forEach((entry) => {
			const classNames = entry.target.getAttribute('data-animate-start').split(' ');

			// console.log(classNames.split(' '));

			entry.target.classList.add(...classNames);

			// when element's is in viewport,
			// animate it!
			if (entry.intersectionRatio > 0) {
				animate(entry.target);
			}
			// remove observer after animation
			observer.unobserve(entry.target);
		});
	});

	// get only these elements,
	// which are not animated yet
	// const elements = [].filter.call(
	//   document.querySelectorAll(SELECTOR),
	//   element => !isAnimated(element, ANIMATE_CLASS_NAME),
	// );

	function handleIntersection(entries) {
		entries.map((entry) => {
			if (entry.isIntersecting) {
				entry.target.classList.add('is-visible');
				observer.unobserve(entry.target);
			}
		});
	}

	const observer = new IntersectionObserver(handleIntersection, { threshold: 0.2 });
	const elements = document.querySelectorAll(SELECTOR);

	// start observing your elements
	elements.forEach((element) => observer.observe(element));
};

/**
 * Gallery gLightbox
 */
INCATRO.gallery = function () {
	console.log('# GALLERY');

	const galleries = document.querySelectorAll('.js-gallery');

	galleries.forEach((gallery) => {
		const galleryId = gallery.getAttribute('data-gallery');
		const gallerySelector = 'js-gallery[data-gallery=' + galleryId + '] .gallery';

		console.log(gallerySelector);

		const lightbox = GLightbox({
			selector: gallerySelector,
			touchNavigation: true,
		});

		const galleryTriggers = gallery.querySelectorAll('.js-gallery-open');

		galleryTriggers.forEach((button) => {
			button.addEventListener('click', function () {
				lightbox.open();
			});
		});
	});

	// const lightbox = GLightbox({
	//     selector: 'js-gallery',
	//     touchNavigation: true
	// });

	// const galleryTrigger  = document.querySelector('.js-gallery');
	// galleryTrigger.addEventListener('click', function(){
	//     lightbox.open()
	// });
};

function initSliders() {
	const heroSlider = document.querySelector('[data-hero-slider]');
	if (!heroSlider) return;
	const glide = new Glide(heroSlider, {
		type: 'carousel',
		perView: 1,
		autoplay: 4000,
		pauseOnHover: false,
	});
	glide.mount();
}

/**
 * Init all the things
 */
window.addEventListener('load', (event) => {
	INCATRO.init();
});
