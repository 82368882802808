/*
 * Ticker
 */
export const Ticker = () => {
	const TICKER_ENABLED_CLASS = 'is-enabled';
	const TICKER_ITEM_ACTIVE_CLASS = 'is-active';
	const TICKER_ITEM_DURATION = 3000;

	const ticker = document.querySelector('.c-ticker');

	if (!ticker) return;

	const tickerItems = ticker.querySelectorAll('.c-ticker__item');
	const tickerItemsLength = tickerItems.length;

	let tickerItemIndex = 0;
	const tickerItemActive = () => {
		tickerItems[tickerItemIndex].classList.add(TICKER_ITEM_ACTIVE_CLASS);
	};

	// Start the ticker
	const tickerStart = () => {
		ticker.classList.add(TICKER_ENABLED_CLASS);
		tickerItemActive();
		setInterval(() => {
			tickerItems[tickerItemIndex].classList.remove(TICKER_ITEM_ACTIVE_CLASS);
			tickerItemIndex = (tickerItemIndex + 1) % tickerItemsLength;
			tickerItemActive();
		}, TICKER_ITEM_DURATION);
	};

	if (ticker) {
		setTimeout(() => {
			tickerStart();
		}, 200);
	}
};
